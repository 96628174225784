<template>
  <b-container>
    <b-row>
      <b-col lg="4" sm="6">
        <statistic-card-horizontal
          icon="CalendarIcon"
          :statistic="waiting"
          :statistic-title="'Menunggu Respon'"
        />
      </b-col>
      <b-col lg="4" sm="6">
        <statistic-card-horizontal
          icon="CalendarIcon"
          :statistic="weekly"
          :statistic-title="'Minggu Ini'"
        />
      </b-col>
      <b-col lg="4" sm="6">
        <statistic-card-horizontal
          icon="CalendarIcon"
          :statistic="monthly"
          :statistic-title="'Bulan Ini'"
        />
      </b-col>
    </b-row>
    <b-row>
      <!-- card collapsible  -->
      <b-col md="12">
        <b-card-actions title="Filter" :collapsed="true" action-collapse>
          <b-row>
            <b-col md="3">
              <b-form-group label="Nama" label-for="name">
                <b-form-input
                  id="name"
                  placeholder="Nama"
                  v-model="filter.name"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Tanggal" label-for="start_date">
                <flat-pickr
                  id="start_date"
                  v-model="filter.start_date"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d' }"
                  placeholder="Start Date"
                />
                <!-- <p>Value: '{{ filter.start_date }}'</p> -->
              </b-form-group>
            </b-col>
            <!-- <b-col md="3">
              <b-form-group label="End Date" label-for="end_date">
                <flat-pickr
                  id="end_date"
                  v-model="filter.end_date"
                  class="form-control"
                  :config="{ dateFormat: 'Y-m-d' }"
                  placeholder="End Date"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="3">
              <b-form-group label="Status" label-for="select_filter">
                <b-form-select
                  id="select_filter"
                  v-model="selectedFilter"
                  :options="options"
                />
                <!-- Selected: <strong>{{ selected }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="mt-1"
                @click="
                  getInquiry(
                    1,
                    filter.name,
                    filter.start_date,
                    filter.end_date,
                    selectedFilter
                  )
                "
              >
                Cari
              </b-button>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-card>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'name'" class="text-nowrap">
            <!-- <b-avatar
                            :src="props.row.avatar"
                            class="mx-1"
                        /> -->
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Created At -->
          <span
            v-else-if="props.column.field === 'created_at'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ moment(props.row.created_at) }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              <span >{{props.row.status_name}}</span>
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-bind:to="{ path: '/sales/quotation/' + props.row.id }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-process
                  @click="trigger(props.row.id)"
                  v-if="props.row.status === 1"
                >
                  <feather-icon icon="CheckCircleIcon" class="mr-50" />
                  <span>Proses</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.modal-reject
                  @click="trigger(props.row.id)"
                  v-if="props.row.status === 1"
                >
                  <feather-icon icon="XCircleIcon" class="mr-50" />
                  <span>Tolak</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="finish(props.row.id)"
                  v-if="props.row.status === 2"
                >
                  <feather-icon icon="CheckCircleIcon" class="mr-50" />
                  <span>Selesai</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ meta.from }} - {{ meta.to }} </span>
              <span class="text-nowrap ml-25">
                dari {{ meta.total }} data
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="meta.total"
                :per-page="meta.per_page"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                  (value) =>
                    getInquiry(
                      value,
                      filter.name,
                      filter.start_date,
                      filter.end_date,
                      selectedFilter
                    )
                "
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <b-row align-h="center">
      <!-- modal change password -->
      <b-modal
        id="modal-reject"
        title="Penolakan Permintaan"
        ok-title="Kirim"
        cancel-variant="outline-secondary"
        centered
        hide-footer
      >
        <validation-observer ref="loginValidation">
          <b-form ref="form" @submit.prevent>
            <b-form-group
              :state="errors"
              label="Alasan Penolakan"
              label-for="reject"
              invalid-feedback="Alasan penolakan tidak boleh kosong"
            >
              <validation-provider
                #default="{ errors }"
                name="reject"
                rules="required"
              >
                <b-form-textarea
                  id="reject"
                  name="reject"
                  type="text"
                  v-model="form.reject_reason"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Alasan Penolakan"
                  required="required"
                />
                <small class="text-danger">{{ errors[0] || errMessage }}</small>
              </validation-provider>
            </b-form-group>

            <b-row>
              <b-col>
                <b-button
                  class="mt-1 mb-2"
                  type="submit"
                  variant="danger"
                  block
                  @click="reject()"
                >
                  Kirim
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

      <b-modal
        id="modal-process"
        title="Proses Penawaran"
        ok-title="Kirim"
        cancel-variant="outline-secondary"
        centered
        hide-footer
      >
        <validation-observer ref="loginValidation">
          <b-form ref="form" @submit.prevent enctype="multipart/form-data">
            <b-row>
              <b-col>
                <b-form-group label="Harga Penawaran" label-for="offering">
                  <cleave
                    id="offering"
                    placeholder="Harga Penawaran"
                    v-model="form.price"
                    class="form-control"
                    :raw="false"
                    :options="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Note" label-for="Note">
                  <b-form-textarea
                    id="note"
                    name="notes"
                    type="text"
                    v-model="form.note"
                    placeholder="Note"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :state="errors"
                  label="File"
                  label-for="File"
                  invalid-feedback="File is required"
                >
                  <b-form-file
                    v-model="form.file"
                    placeholder="Upload file disini..."
                    drop-placeholder="Upload file disini..."
                    id="extension"
                    accept=".pdf"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-button
                  class="mt-1 mb-2"
                  type="submit"
                  variant="success"
                  block
                  @click="process()"
                >
                  Proses
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- end modal change password -->
    </b-row>
  </b-container>
</template>

<script>
import {
  VBModal,
  BForm,
  BButton,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BContainer,
  BFormTextarea,
  BFormFile,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import Cleave from "vue-cleave-component";

export default {
  components: {
    VueGoodTable,
    ToastificationContent,
    StatisticCardHorizontal,
    BCardActions,
    flatPickr,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BContainer,
    BFormTextarea,
    BFormFile,
    Cleave,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Pelanggan",
          field: "user_name",
        },
        {
          label: "Tanggal",
          field: "created_at",
          width: "200px",
        },
        {
          label: "Status",
          field: "status",
          width: "200px",
        },
        {
          label: "",
          field: "action",
          width: "50px",
        },
      ],
      filter: {
        name: "",
        start_date: "",
        end_date: "",
      },
      selectedFilter: "",
      options: [
        { value: "", text: "Semua" },
        { value: "1", text: "Menunggu respon" },
        { value: "2", text: "Dalam proses" },
        { value: "3", text: "Ditolak" },
        { value: "4", text: "Selesai" },
      ],
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
      id: null,
      rows: [],
      meta: {
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
        per_page: 10,
        total: 0,
      },
      permissions: [],
      errors: "",
      errMessage: "",
      searchTerm: "",
      waiting: 0,
      weekly: 0,
      monthly: 0,
      form: {
        price: "",
        file: "",
        reject_reason: "",
        note: "",
      },
      updated: false,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        1: "light-warning",
        2: "light-primary",
        3: "light-danger",
        4: "light-success",
        5: "light-danger",
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getAllPermissions();
    this.getStatistic();
    this.getInquiry(1, "", "", "", "");
  },
  methods: {
    moment(date) {
      return moment(date).format("DD-MM-YYYY, hh:mm");
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getInquiry($page, $name, $start_date, $end_date, $status) {
      this.$http
        .get(
          "request/quotations?page=" +
            $page +
            ($name ? "&name=" + this.filter.name : "") +
            ($start_date ? "&start_date=" + this.filter.start_date : "") +
            ($end_date ? "&end_date=" + this.filter.end_date : "") +
            ($status ? "&status=" + this.selectedFilter : "")
        )
        .then((res) => {
          this.rows = res.data.data;
          var meta = res.data.meta;
          if (meta.from != null) {
            this.meta.from = meta.from;
          }
          if (meta.to != null) {
            this.meta.to = meta.to;
          }
          this.meta.current_page = meta.current_page;
          this.meta.last_page = meta.last_page;
          this.meta.per_page = meta.per_page;
          this.meta.total = meta.total;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    getStatistic() {
      this.$http
        .get("request/quotations/statistic")
        .then((response) => {
          this.waiting = response.data.data.waiting;
          this.weekly = response.data.data.weekly;
          this.monthly = response.data.data.monthly;
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    process() {
      var newPrice = this.form.price;

      if (newPrice != null) {
        newPrice = newPrice.replace(/\,/g, "");
      }

      const formData = new FormData();
      if (this.form.file != null) {
        formData.append("file", this.form.file);
      }
      formData.append("note", this.form.note);
      formData.append("price", newPrice);

      this.$http
        .post("request/quotation/" + this.id + "/process", formData)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penawaran diproses",
              variant: "success",
            },
          });
          location.href = "/sales/quotations";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    finish($id) {
      this.$http
        .post("request/quotation/" + $id + "/finish")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penawaran selesai",
              variant: "success",
            },
          });
          location.href = "/sales/quotations";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    reject() {
      this.$http
        .post("request/quotation/" + this.id + "/reject", {
          reject_reason: this.form.reject_reason,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: "Permintaan penawaran ditolak",
              variant: "danger",
            },
          });
          location.href = "/sales/quotations";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notifikasi",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    trigger($id) {
      this.id = $id;
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>